/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import opportunitiesSlider from './components/opportunities-slider';
import map from './blocks/map';
import Timeline from './blocks/timeline';
import newsArchive from './blocks/news-archive';
import gallerySlider from './blocks/gallerySlider';
import resourceArchive from './blocks/resource-archive';
import latestComunications from './blocks/latestCommunications';
import events from './blocks/events';
import Animations from './global/animations';
import academy from './blocks/academy';

document.addEventListener('DOMContentLoaded', () => {
	let siteContainer = document.querySelector('.site-container');

	if (siteContainer) {
		siteContainer.classList.add('loaded');
	}
	svg4everybody();
	MobileMenu();
	opportunitiesSlider();
	Timeline();
	newsArchive();
	gallerySlider();
	resourceArchive();
	latestComunications();
	events();
	Animations();

	if (document.querySelector('.academy-banner')) {
		academy();
	}

	if (document.querySelector('section.map')) {
		map();
	}
});
