export default function MobileMenu() {
	const mobileMenuToggles = document.querySelectorAll('.js-menu-toggle');
	const mobileLinks = document.querySelectorAll('.resp-menu .sub-menu li');

	if (!mobileMenuToggles) {
		return;
	}

	mobileLinks.forEach((link) =>{
		link.addEventListener('click', () => {
			const body = document.querySelector('body');
			body.classList.remove('menu-open');
		}
	)
})

	mobileMenuToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			const body = document.querySelector('body');
			//toggle.classList.toggle('menu-toggle--active');
			body.classList.toggle('menu-open');
		});
	});
}