import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const $ = jQuery;
let map = null;
let hoveredPolygonId = null;
let locations = [];
let isoCodeCoordinates = [];
let activeLocation;

// Check if mapObject exists before using it
if (typeof mapObject !== 'undefined') {
	if (mapObject.activeLocation) {
		activeLocation = mapObject.activeLocation;
	}
}

export default function () {
	locations = mapObject.locations;

	if (locations.length === 0) {
		return;
	}

	initMap();
}

async function initMap() {
	mapboxgl.accessToken = 'pk.eyJ1Ijoib21lbi1lc2FpYyIsImEiOiJjbG9paGIyeTgxaGowMmlxcGN2djk1dDE0In0.v5d-PfC-4VDb_32bSBvkfg';
	map = new mapboxgl.Map({
		container      : 'map-block-container',
		style          : 'mapbox://styles/omen-esaic/clvf12jp1013501qpccuifjl2',
		center         : [3.2551, 54.5260],
		zoom           : 3.5,
		bearing        : 0,
		interactive    : true,
		dragPan        : false,
		doubleClickZoom: false,
		dragRotate     : false,
		dragging       : false,
		scrollZoom     : false,
		pitch          : 0,
		// Disable hand cursor
		draggableCursor: false,
	});

	// Use Mapbox Geocoding API to get coordinates for each ISO code
	isoCodeCoordinates = await Promise.all(
		locations.map(async (country) => {
			let isoCode = country.isoCode;
			isoCode = isoCode.toLowerCase();
			const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${isoCode}&country=${isoCode}&proximity=ip&types=country&access_token=${mapboxgl.accessToken}`);
			const data = await response.json();
			const coordinates = data.features.length > 0 ? data.features[0].geometry.coordinates : [0, 0]; // Default to [0, 0] if no coordinates found
			isoCode = isoCode.toUpperCase();
			return {
				termId: country.term_id,
				isoCode,
				coordinates,
			};
		}),
	);

	// Use Popup class to create popups
	let popup = new mapboxgl.Popup({
		closeButton : false,
		closeOnClick: false,
		offset      : 25,
	});

	let popupContent = `<div class="map__logos">`;
	popupContent += `</div>`;
	popup.setHTML(popupContent);

	// Add markers to map
	isoCodeCoordinates.forEach(({
		isoCode,
		coordinates,
	}) => {
		if (isoCode === 'GR') {
			coordinates = [22.8, 37.9838];
		}
		if (isoCode === 'AT') {
			coordinates = [14.3001435907641, 47];
		}
		if (isoCode === 'HR') {
			coordinates = [16.6791068850861, 45];
		}
		if (isoCode === 'SI') {
			coordinates = [14.6940763981247, 45.5];
		}
		let marker = new mapboxgl.Marker(
			{
				element: createCustomMarker(),
				anchor : 'bottom',
			},
		).setLngLat(coordinates).addTo(map);

		let markerElement = marker.getElement();
		markerElement.dataset.isoCode = isoCode;

		markerElement.addEventListener('mouseover', (e) => {
			const isoCode = e.currentTarget.dataset.isoCode;
			const country = locations.find(country => country.isoCode === isoCode);

			if (country && country !== activeLocation) {
				activeLocation = country;
				popup.setLngLat(coordinates).addTo(map);
				getPartnerData(country);
			}
		});
	});

	// Add country polygons to map
	map.on('load', () => {
		map.addSource('countries', {
			'type': 'vector',
			'url' : 'mapbox://mapbox.country-boundaries-v1',
		});

		map.addLayer({
			'id'          : 'country-fills',
			'type'        : 'fill',
			'source'      : 'countries',
			'source-layer': 'country_boundaries',
			'filter'      : ['in', ['get', 'iso_3166_1'], ['literal', locations.map(country => country.isoCode)]],
			'paint'       : {
				'fill-color': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					'#fff',  // Color when hovered
					'#fff',  // Default color
				],
			},
		});

		map.addLayer({
			'id'          : 'country-borders',
			'type'        : 'line',
			'source'      : 'countries',
			'source-layer': 'country_boundaries',
			'layout'      : {},
			'paint'       : {
				'line-color': '#CCE0F5',
				'line-width': 1, // Increased line width for a bolder look
			},
		});

		// Add hover effect and open popup
		// map.on('mousemove', 'country-fills', (e) => {
		// 	if (e.features.length > 0) {
		// 		const isoCode = e.features[0].properties.iso_3166_1;
		// 		const country = locations.find(country => country.isoCode === isoCode);
		// 		if (country && country !== activeLocation) {
		// 			const coordinates = isoCodeCoordinates.find(country => country.isoCode === isoCode).coordinates;
		// 			popup.setLngLat(coordinates).addTo(map);
		// 			activeLocation = country;
		// 			getPartnerData(country);
		// 		}
		// 	}
		// });

		// map.on('mouseleave', 'country-fills', (e) => {
		// 	popup.remove();
		// 	activeLocation = null;
		// });
	});
}

function getPartnerData(country) {
	if (!country) {
		return;
	}

	const logosContainer = $('.map__logos');
	const postsContainer = $('#map-posts .map__logo');
	logosContainer.empty();

	if (logosContainer) {
		const postsForDisplay = postsContainer.filter((index, post) => {
			let postIsoCodes = $(post).data('iso-codes').split(',');
			return postIsoCodes.includes(country.isoCode);
		});

		if (postsForDisplay.length > 0) {
			postsForDisplay.each((index, post) => {
				let clonedPost = $(post).clone();
				logosContainer.append(clonedPost);
			});
		} else {
			logosContainer.remove();
		}
	}
}

function createCustomMarker() {
	const mapPin = `<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="10.9622" cy="10.4622" r="10.4622" fill="#00CCCC" fill-opacity="0.3"/>
		<circle cx="10.9615" cy="10.4615" r="5.23108" fill="#00CCCC" fill-opacity="0.3"/>
		</svg>
	`;

	let div = document.createElement('div');
	let divDot = document.createElement('div');
	div.classList.add('map__marker');
	divDot.classList.add('map__marker-dot');

	div.appendChild(divDot);
	return div;
}
