export default function events() {
    let events = document.querySelector('.events');
    if (!events) return;

    let filters = events.querySelectorAll('.events__buttons .c-button');
    let upcomingBlock = events.querySelector('.events__posts--upcoming');
    let pastBlock = events.querySelector('.events__posts--past');
    let noUpcoming = events.querySelector('.no-events-upcoming');
    let noPast = events.querySelector('.no-events-past');

    const showBlock = (blockToShow, blockToHide, noEventsMessageToShow, noEventsMessageToHide) => {
        if (blockToShow) blockToShow.classList.add('active');
        if (blockToHide) blockToHide.classList.remove('active');
        if (noEventsMessageToShow) noEventsMessageToShow.classList.remove('hidden');
        if (noEventsMessageToHide) noEventsMessageToHide.classList.add('hidden');
    };

    const activateButton = (buttonToActivate, buttonToDeactivate) => {
        if (buttonToActivate) buttonToActivate.classList.add('active');
        if (buttonToDeactivate) buttonToDeactivate.classList.remove('active');
    };

    // Initial display logic
    if (upcomingBlock && upcomingBlock.children.length > 0) {
        showBlock(upcomingBlock, pastBlock, null, noPast);
        activateButton(document.querySelector('.js-filter-upcoming'), document.querySelector('.js-filter-past'));
    } else if (pastBlock && pastBlock.children.length > 0) {
        showBlock(pastBlock, upcomingBlock, null, noUpcoming);
        activateButton(document.querySelector('.js-filter-past'), document.querySelector('.js-filter-upcoming'));
    } else {
        // If no events, show messages
        if (noUpcoming) noUpcoming.classList.remove('hidden');
        if (noPast) noPast.classList.remove('hidden');
    }

    filters.forEach(button => {
        button.addEventListener('click', function () {
            if (button.classList.contains('active')) return;

            let activeButton = events.querySelector('.events__buttons .c-button.active');
            if (activeButton) activeButton.classList.remove('active');

            button.classList.add('active');

            if (button.classList.contains('js-filter-upcoming')) {
                showBlock(upcomingBlock, pastBlock, noUpcoming, noPast);
            } else {
                showBlock(pastBlock, upcomingBlock, noPast, noUpcoming);
            }
        });
    });
}