import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';

export default function opportunitiesSlider() {
  let testimonialSlider = document.querySelector('.opportunities__slider');

  if (!testimonialSlider) return;

  const swiper = new Swiper('.opportunities__slider', {
    slidesPerView: 1.1,
    spaceBetween: 16,
    loop: true,
    modules: [Navigation],
    navigation: {
      nextEl: '.opportunities__slider-next',
      prevEl: '.opportunities__slider-prev',
    },
    speed: 500,
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 120,
      },
    },
  });
}