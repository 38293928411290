export default function academy() {
    window.addEventListener("load", function() {
        const academyBanner = document.querySelector(".academy-banner");
        if (academyBanner) {
            const subscribeSection = document.querySelector(".subscribe");
            if (subscribeSection) {
                subscribeSection.remove();
            }
        }
    });
}