import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export default function Timeline() {
  let timelineSlider = document.querySelector('.timeline__slider');

  if (!timelineSlider) return;

  const swiper = new Swiper('.timeline__slider', {
    slidesPerView: 'auto',
    spaceBetween: 12,
    speed: 1100,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      snapOnRelease: false,
    },
  });
}