import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
	// Make selectors for ScrollTrigger
	let animateUp = document.querySelectorAll('.hero__title, .hero__subtitle, .hero__buttons, .text-with-image__content, .map:first-child, .work:first-child, .resources-archive:first-child, .news-archive:first-child, .contact-us:first-child');

	if (!animateUp.length) {
		return;
	}

	// Hero animation with ScrollTrigger batch
	ScrollTrigger.batch(animateUp, {
		onEnter: (batch) =>
			gsap.from(batch, {
				opacity: 0,
				y: 50,
				duration: 1.5,
				ease: 'power1.out',
				stagger: {
					each: 0.1, // Stagger each element with 0.1 second delay
					from: 'start', // Stagger from the start
				},
			}),
		start: 'top 95%',
		end: 'bottom 10%',
	});



	let animateGraphics = document.querySelectorAll('.hero__graphic,.text-with-image__graphic');

	if(!animateGraphics.length) {
		return;
	}
	// Hero graphic animation with ScrollTrigger
	ScrollTrigger.batch(animateGraphics, {
		onEnter: (batch) =>
			gsap.to(batch, {
				yPercent: -100,
				duration: 1.1,
				ease: 'power1.out',
				stagger: {
					each: 0.1, // Stagger each element with 0.1 second delay
					from: 'start', // Stagger from the start
				},
			}),
		start: 'top 95%',
		end: 'bottom 10%',
	});

	// Initialize ScrollTrigger
	ScrollTrigger.refresh();

}
