import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export default function gallerySlider() {
	let gallerySlider = document.querySelector('.gallery__slider');

	if (!gallerySlider) return;

	const swiper = new Swiper('.gallery__slider', {
		slidesPerView: 1.1,
		spaceBetween: 16,
		speed: 1100,
		breakpoints: {
			400: {
				slidesPerView: 2.2,
			},
			768: {
				slidesPerView: 'auto',
			},
		},
	});
}
