export default function newsArchive() {
	let newsBlock = document.querySelector('.news-archive');
	if(!newsBlock) return;

	const $ = jQuery;

	function setupNewsArchive() {
		let newsArchivePaginationItems = document.querySelectorAll('.news-archive .pagination .page-numbers');

		if (newsArchivePaginationItems) {
			newsArchivePaginationItems.forEach(item => {
				item.addEventListener('click', (e) => {
					e.preventDefault();
					let pageNumber = item.textContent;
					let currentPage = newsBlock.querySelector('.news-archive .pagination .current').textContent;

					if (item.classList.contains('next')) {
						pageNumber = parseInt(currentPage) + 1;
						}

					if (item.classList.contains('prev')) {
						pageNumber = parseInt(currentPage) - 1;
					}


					getnewsArchivePosts(pageNumber);
				});
			});
		}
	}

	function getnewsArchivePosts(pageNumber) {
		let newsBlockPostsContainer = document.querySelector('.news-archive__posts');

		$.ajax({
			url: menaObject.ajaxurl,
			type: 'GET',
			dataType: 'html',
			data: {
				action: 'getNewsArchivePosts',
				pageNumber: pageNumber,
			},
			beforeSend: function () {
				newsBlockPostsContainer.classList.add('loading');
			},
			success: function (response) {
				response = JSON.parse(response);
				newsBlockPostsContainer.classList.remove('loading');

				if (response.success && response.data.posts) {
					newsBlockPostsContainer.innerHTML = response.data.posts;
				}

				if (response.success && response.data.pagination) {
					document.querySelector('.news-archive .pagination').innerHTML = response.data.pagination;
				}

				// Callback that applies event listeners to the new pagination items
				setupNewsArchive();

				// Scroll to the top of the news block
				document.querySelector('.news-archive').scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			},
			error: function (error) {
				newsBlockPostsContainer.classList.remove('loading');
				console.error(error);
			},
		});
	}

	setupNewsArchive();
}