import {gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function resourceArchive() {
	const resourceArchive = document.querySelector('.resources-archive');

	if (!resourceArchive) {
		return;
	}

	let $ = jQuery;

	// handling toggling category dropdown
	const resourceTaxonomy = resourceArchive.querySelector('.js-toggle-taxonomy');
	resourceTaxonomy.addEventListener('click', function (e) {
		if (e.target.closest('.resources-archive__taxonomy-dropdown')) {
			return;
		}
		resourceTaxonomy.classList.toggle('active');
	});

	let setupGsap = () => {
		let resourceArchiveItems = document.querySelectorAll('.resources-archive .card-resource');
		if (resourceArchiveItems) {
			resourceArchiveItems.forEach((item) => {
				gsap.to(item, {
				scrollTrigger: {
					trigger: item,
					start: 'top 80%',
					end: 'end 20%',
					onEnter: () => item.classList.add('active'),
					onLeave: () => item.classList.remove('active'),
					onEnterBack: () => item.classList.add('active'), // Optional: Add class again when scrolling back past the trigger
					onLeaveBack: () => item.classList.remove('active'), // Optional: Remove class again when scrolling back before the trigger
				},
				duration: 0.5,
				});
			});
		}
	};

	setupGsap();

	// Handle turning on filters inside the dropdown
	const taxonomyItems = resourceArchive.querySelectorAll('.resources-archive__taxonomy-dropdown-item');
	if(taxonomyItems) {
		taxonomyItems.forEach((item) => {
			item.addEventListener('click', function () {
				item.classList.toggle('active');
				let resourceArchiveStatus = resourceArchive.querySelector('.resources-archive__status');

				// Find all active taxonomy items and add this html to the status block
				let activeTaxonomyItems = Array.from(document.querySelectorAll('.resources-archive__taxonomy-dropdown-item.active'));

				if (activeTaxonomyItems.length > 0) {
					resourceArchiveStatus.innerHTML = activeTaxonomyItems.map((item) => {
						return `<span class="resources-archive__status-item" data-id="${item.dataset.id}">${item.textContent} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
					<path stroke="#036" stroke-linecap="round" stroke-width="2" d="M1.152 1.576 12.465 12.89m-11.313-.312L6.505 6.92m5.96-5.657-4 3.738"/>
				</svg></span>`;
					}).join('');
				} else {
					resourceArchiveStatus.innerHTML = '';
				}

				// Find all active filters .resources-archive__status-item
				let activeFilters = Array.from(document.querySelectorAll('.resources-archive__status-item'));
				// Add event listener to remove filter
				activeFilters.forEach((item) => {
					item.addEventListener('click', function () {
						// Remove active class from taxonomy item
						let taxonomyItem = document.querySelector(`.resources-archive__taxonomy-dropdown-item[data-id="${item.dataset.id}"]`);
						taxonomyItem.classList.remove('active');
						// Remove item from dom
						item.remove();
						getResources();
					});
				});
				getResources();
			});
		});
	}

	//Event listener for search input that calls the function 2s after the user stops typing
	let searchInput = resourceArchive.querySelector('.resources-archive__search input');
	let searchTimeout = null;
	searchInput.addEventListener('input', function () {
		console.log(searchInput.value.length);
		// if more than 3 characters
		if (searchInput.value.length > 2) {
			clearTimeout(searchTimeout);
			searchTimeout = setTimeout(function () {
				getResources();
			}, 300);
		}
	});

	// Event listener for sorting by latest
	let sortByLatest = resourceArchive.querySelector('.js-sort-by-latest');
	sortByLatest.addEventListener('click', function () {
		if (!sortByLatest.classList.contains('active')) {
			let sortByTitle = resourceArchive.querySelector('.js-sort-by-title');
			sortByTitle.classList.remove('active');
			sortByLatest.classList.add('active');
			getResources();
		}
	});

	// Sort by title
	let sortByTitle = resourceArchive.querySelector('.js-sort-by-title');
	sortByTitle.addEventListener('click', function () {
		if (!sortByTitle.classList.contains('active')) {
			let sortByLatest = resourceArchive.querySelector('.js-sort-by-latest');
			sortByLatest.classList.remove('active');
			sortByTitle.classList.add('active');
			getResources();
		}
	});

	// Sort by title descending & ascending
	let sortByTitleDescending = resourceArchive.querySelector('.js-arrow-up');
	let sortByTitleAscending = resourceArchive.querySelector('.js-arrow-down');

	sortByTitleDescending.addEventListener('click', function () {
		let sortByLatest = resourceArchive.querySelector('.js-sort-by-latest');
		let sortByTitle = resourceArchive.querySelector('.js-sort-by-title');
		sortByLatest.classList.remove('active');
		sortByTitle.classList.add('active');
		if (!sortByTitleDescending.classList.contains('active')) {
			sortByTitleDescending.classList.add('active');
			sortByTitleAscending.classList.remove('active');
			getResources();
		}
	});

	sortByTitleAscending.addEventListener('click', function () {
		let sortByLatest = resourceArchive.querySelector('.js-sort-by-latest');
		let sortByTitle = resourceArchive.querySelector('.js-sort-by-title');
		sortByLatest.classList.remove('active');
		sortByTitle.classList.add('active');
		if (!sortByTitleAscending.classList.contains('active')) {
			sortByTitleAscending.classList.add('active');
			sortByTitleDescending.classList.remove('active');
			getResources();
		}
	});

	function setupArchive() {
		// Handle pagination
		let resourcesPaginationItems = document.querySelectorAll('.resources-archive .page-numbers');
		if (resourcesPaginationItems) {
			resourcesPaginationItems.forEach((item) => {
				item.addEventListener('click', (e) => {
					e.preventDefault();
					let pageNumber = item.textContent;
					let currentPage = resourceArchive.querySelector('.resources-archive .current').textContent;

					if (item.classList.contains('next')) {
						pageNumber = parseInt(currentPage) + 1;
					}

					if (item.classList.contains('prev')) {
						pageNumber = parseInt(currentPage) - 1;
					}

					getResources(pageNumber);

					// Scroll to the top of the news block
					document.querySelector('.resources-archive').scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});
			});
		}
	}

	function getResources(pageNumber) {
		let resourcesContainer = document.querySelector('.resources-archive__list');

		let sortByLatest,sortByTitleDescending,sortByTitleAscending, sortByTitle;

		let sortByLatestItem = document.querySelector('.js-sort-by-latest');
		if(sortByLatestItem.classList.contains('active')) {
			sortByLatest = true;
		} else {
			sortByLatest = false;
		}

		sortByTitle = document.querySelector('.js-sort-by-title');
		if(sortByTitle.classList.contains('active')) {
			if(document.querySelector('.js-arrow-up').classList.contains('active')) {
				sortByTitleDescending = true;
			} else {
				sortByTitleAscending = true;
				sortByTitleDescending = false;
			}

			if(document.querySelector('.js-arrow-down').classList.contains('active')) {
				sortByTitleAscending = true
			} else {
				sortByTitleAscending = false;
				sortByTitleDescending = true;
			}
		}

		if(!pageNumber) {
			pageNumber = document.querySelector('.resources-archive .current');
			if (pageNumber) {
				pageNumber = pageNumber.textContent;
			} else {
				pageNumber = 1;
			}
		}

		let workingGroups = Array.from(document.querySelectorAll('.resources-archive__taxonomy-dropdown-item.active')).map((item) => item.dataset.id);

		let search = document.querySelector('.resources-archive__search input').value;

	    // if 0 or less than 2 then set to empty string
		if (search.length < 2) {
			search = '';
		}

		// Add pageNumber to data array
		let data = {
			search: search,
			// Get all active taxonomy items
			workingGroups: workingGroups,
			pageNumber: pageNumber,
			sortByLatest: sortByLatest,
			sortByTitleDescending: sortByTitleDescending,
			sortByTitleAscending: sortByTitleAscending
		};

		console.log(data);

		$.ajax({
			url: menaObject.ajaxurl,
			type: 'GET',
			dataType: 'html',
			data: {
				action: 'getResources',
				data: data,
			},
			beforeSend: function () {
				resourcesContainer.classList.add('loading');
			},
			success: function (response) {
				response = JSON.parse(response);
				resourcesContainer.classList.remove('loading');

				if (response.success && response.data.posts) {
					resourcesContainer.innerHTML = response.data.posts;
				}

				if (response.success && response.data.pagination) {
					document.querySelector('.resources-archive .pagination').innerHTML = response.data.pagination;
				} else {
					document.querySelector('.resources-archive .pagination').innerHTML = '';
				}

				// Callback that applies event listeners to the new pagination items
				setupArchive();
				setupGsap();
			},
			error: function (error) {
				resourcesContainer.classList.remove('loading');
				console.error(error);
			},
		});
	}

	setupArchive();
}