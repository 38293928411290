export default function latestComunications() {
	let latestComunications = document.querySelector('.latest-communications');

	if(!latestComunications) return;

	let filters = latestComunications.querySelectorAll('.events__buttons .c-button');

	filters.forEach(button => {
		button.addEventListener('click', function(ev) {
			ev.preventDefault();

			if(button.classList.contains('active')) {
				return;
			} else {
				let activeButton = latestComunications.querySelector('.events__buttons .c-button.active');
				activeButton.classList.remove('active');
				button.classList.add('active');
			}

			let activeElement = latestComunications.querySelector('.latest-communications__posts.active');
			activeElement.classList.remove('active');

			if(button.classList.contains('js-filter-news')) {

				let newsBlock = latestComunications.querySelector('.latest-communications__posts--news');
				newsBlock.classList.add('active');
			}

			if(button.classList.contains('js-filter-events')) {
				let eventsBlock = latestComunications.querySelector('.latest-communications__posts--events');
				eventsBlock.classList.add('active');
			}

			if(button.classList.contains('js-filter-materials')) {
				let materialsBlock = latestComunications.querySelector('.latest-communications__posts--materials');
				materialsBlock.classList.add('active');
			}

		});
	});
}